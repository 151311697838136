// .container.pre-assessment { width: 95%; max-width: 1100px; align-items: center; margin: 3rem auto; box-sizing: border-box; }
.container.pre-assessment { width: 95%; max-width: 1100px; align-items: center; margin: 20px auto; box-sizing: border-box; height: 600px; }
.pre-assessment { 
  // & { background-color: #fff; color: $color2; width: 95%; max-width: 1100px; margin: 0px auto 15px; padding: 15px; font-family: $primaryfont; display: flex; flex-direction: column; justify-content: center; }
  & { color: $color2; width: 95%; max-width: 1100px; margin: 0px auto 15px; padding: 15px; font-family: $primaryfont; display: flex; flex-direction: column;  }
  // h1 { font-size: 30px; font-weight: 700; line-height: 35px; text-align: center; color: #100F2E; max-width: 70ch; margin: 48px auto; font-family: $primaryfont; }
  h1 { font-size: 36px; font-weight: 700; line-height: 43px; text-align: center; color: #fff; max-width: 70ch; margin: 30px auto 48px ; font-family: $fontBarlow; }
  .assessment-categories { 
    & { display: flex; flex-wrap: wrap; gap: 30px; justify-content: center; }
    .category { 
      // & { align-items: start; width: 190px; height: 190px; background-color: #47466C; border-radius: 10px; display: flex; justify-content: center; align-items: center; flex-direction: column; padding-top: 12px; cursor: pointer; }
      & { position: relative; align-items: start; width: 250px; height: 210px; background-color: #fff; border-radius: 10px; display: flex; justify-content: end; align-items: center; flex-direction: column; cursor: pointer; padding-bottom: 30px; }
      // .right-tick-img-container { width: 24px; height: 24px; background-color: #F26722; border-radius: 50px; text-align: center; display: flex; justify-content: center; align-items: center; margin-bottom: 8px; }
      .right-tick-img-container { width: 38px; height: 38px; background-color: #99BB00; border-radius: 50px; text-align: center; display: flex; justify-content: center; align-items: center; margin-bottom: 8px; position: absolute; top: 18px; }
      .img-container { 
        & { display: flex; justify-content: center; align-items: center; }
        img { width: 91px; height: 82px; }
      }
      // .text { font-size: 21px; line-height: 27px; font-weight: 700; text-align: center; color: white; font-family: $primaryfont; padding: 4px 8px; margin: 0; }
      .text { font-size: 24px; line-height: 36px; font-weight: 700; text-align: center; color: #004F88; font-family: $primaryfont; padding: 6px 8px; margin: 0; }
    }
    // .selected { transition: transform 0.3s ease-in-out; transform: scale(1.05); }
    .selected { 
      & { transition: transform 0.3s ease-in-out; border: 4px solid #99BB00; height: 204  px; transform: scale(1); }
      .text {
        color: #99BB00;
      }
    }

    .hover {
      // & { border: 4px solid #99BB00; }
      .text { color: #99BB00;  }
    }
    .non-selected { color: rgba(255, 255, 255, 0.75); opacity: 0.5; }
    .non-selected { color: rgba(247, 255, 240, 1); opacity: 1; }
  }
  .button { 
    & { margin: 45px auto; width: 100%; text-align: center; }
    // .cta { cursor: pointer; color: #fff; background-color: #F26722; border: none; border-radius: 5px; padding: 12px 15px; font-family: $primaryfont; font-weight: 700; font-size: 21px; line-height: 25px; text-transform: uppercase; width: 100%; max-width: 200px; }
    .cta { cursor: pointer; color: #fff; background-color: #F26722; border: none; border-radius: 5px; padding: 12px 15px; font-family: $primaryfont; font-weight: 700; font-size: 21px; line-height: 25px; text-transform: uppercase; width: 100%; max-width: 200px; display: none; }
    .cta:hover { 
      & { background-color: #4C5454; color: #fff; }
    }
  }
}


@media screen and (max-width: 1100px) {
}


@media screen and (max-width: 830px) {
  .container.pre-assessment { width: 95%; }
  .pre-assessment { 
    & { width: 95%; } 
    .assessment-categories { 
      .category { 
        & { align-self: flex-start; width: 168px; height: 151px; padding-bottom: 10px;   }
        .img-container { 
          & { width: 60px; height: 60px; top: 14px; }
          img { width: 100%; height: 100%; }
        }
        //  .text { font-size: 16px; line-height: 20px; }
      }
      .selected { transition: transform 0.3s ease-in-out;  height: 148px;  }
    }
    .pre-assessment .button .cta:hover { background-color: #4C5454; color: #fff; }
  }
}



@media screen and (max-width: 650px) {
  .pre-assessment {
    h1 { font-size: 21px; line-height: 25px; margin: 10px auto 59px; }
    .assessment-categories { 
      & { gap: 18px; flex-direction: column; }
      .category { 
        & { align-self: flex-start; width: 168px; height: 151px; padding-bottom: 6px; justify-content: end; }
        .right-tick-img-container { 
          & { width: 25px; height: 25px; }
          img { width: 80%; }
        }
        .img-container { 
          & { width: 62px; }
          img { width: 100%; height: 100%; object-fit: contain; }
        }
        .text { font-size: 16px; line-height: 20px; padding: 8px 0; }
      }
      .selected { transform: none; box-sizing: border-box; height: 161px; }
    }
    .pre-assessment .button .cta:hover { background-color: #4C5454; color: #fff; }
  }
}

