
  .invite-section-container{ & { display: flex; justify-content: center; flex-direction: column; color: #555555; font-family: $primaryfont; letter-spacing: 1px; }
    // h2 { font-size: 36px; font-weight: 700; text-align: center; margin-bottom: 21px; }
    h2 { font-size: 36px; font-weight: 700; text-align: center; margin: 26px auto; font-family: $fontLato; line-height: 43px; }
    p { font-size: 21px; font-weight: 400; text-align: center; max-width: 70ch; margin: 14px auto; font-family: $fontLato; }
    .invite-container { & { display: flex; align-items: center; justify-content: center; font-size: 21px; margin: 5px auto 15px; gap: 16px; text-align: center; max-width: 1100px; box-sizing: border-box; } 
      h3 { color: #555555; font-size: 20px; margin-bottom: 2px; margin-top: 2px; }
      p { color: #555555; font-size: 20px; font-weight: 400; margin-top: 2px; }
      .calibrate, .benchmark, .assess { width: 28vw; margin-top: 20px; }
      .benchmark {
        // .benchmark-img { width: auto; }
        .benchmark-img { width: auto; margin-bottom: 4px; }
        img { width: 59px; height: 73px; } }
    }
    .cta-btn {
      & { display: inline-block; margin: 25px auto 40px; color: #fff; padding: 0; border-radius: 5px; max-width: 280px; }
      a { display: flex; justify-content: center; text-decoration: none; color: #fff; }
      button { border: 2px solid #93BF42; width: max-content; max-width: 100%; display:  inline-block;  background-color: #93bf42; padding: 5px 25px; color: #fff; font-family: $primaryfont; font-size: 21px; font-weight: 700; height: 50px; border-radius: 35px; }
      button:hover { background-color: #ffffff; color: #93BF42; }
    }
  }


//   .invite-container {
//     & { margin: 50px auto 15px; padding: 30px 30px 50px; font-family: $primaryfont; font-size: 21px; line-height: 32px; color: #343333; background-color: #ffffff; text-align: center; max-width: 1100px; box-sizing: border-box; border-radius: 10px; }
//     .invite-container-spacer { 
//       h3 { font-size: 36px; font-weight: 700; line-height: 43px; margin: 1.5rem auto; padding: 0 15px; color: #202945; }
//      }
//     p { font-weight: 400; font-size: 21px; line-height: 25.2px; color: #202020; margin: 15px auto; width: 60ch; }
//     p { max-width: 72ch; margin: 1rem auto; width: auto; }
//     .height30 { height: 30px; }
//     .invite-reasons {
//       & { display: flex; padding: 73px 0 30px; max-width: 1400px; margin: 0 auto; }
//       .reason { 
//         & { flex: 0 1 33%; font-size: 21px; padding: 0 10px; max-width: 26ch; margin: 0 auto; }
//         span { color: #435B63; font-weight: 700; }
//         img { height: 75px; }
//       }
//     }
//     .cta-btn {
//       & { display: inline-block; margin: 20px auto 0; color: #fff; padding: 0; border-radius: 5px; width: 100%; max-width: 280px; }
//       // &:hover { border-radius: 25px; background-color: #435B63; transition: 0.3s; color: #fff; }
//       a { display: block; text-decoration: none; color: #fff; }
//       button { width: max-content; max-width: 100%; display:  inline-block;  background-color: #ED2028; padding: 5px 25px; color: #fff; font-family: $primaryfont; font-size: 21px; font-weight: 700; height: 50px; border-radius: 5px; }
//       button:hover { border-radius: 5px; background-color: #435B63; transition: 0.3s; color: #fff; }
//     }
//     // .cta-btn {
//     //   & { width: 100%; max-width: 250px; } 
//     //   button { font-size: 21px; font-weight: 700; padding: 11px 10px; letter-spacing: 1px; width: 100%; }
//     // }
//   }


// @media only screen and (max-width: 550px) {
//   main .invite-container .invite-reasons .reason { flex: 0 1 100%; font-size: 18px; margin-bottom: 50px; margin-top: 20px; }
//   main .invite-container { max-width: 95%; font-size: 18px; padding: 0 15px 30px; }
//   main .invite-container p { font-size: 18px; }
//   main .invite-container p br { content: ""; }
//   main .invite-container p br:after { content: " "; }
//   main .invite-container .invite-container-spacer h3 { font-size: 21px; line-height: 28px; }
//   main .invite-container .invite-reasons { flex-wrap: wrap; padding: 20px 0 20px; }
// }
@media only screen and (max-width: 550px) {
  .invite-section-container h1 { font-size: 24px; }
  main .invite-section-container .invite-container p { font-size: 18px; font-weight: 400; line-height: 30px; font-family: $fontLato; }
  .invite-section-container .cta-btn { & { margin: 0 auto 40px; } }
  .invite-section-container .invite-container { flex-direction: column; }
  .invite-section-container .invite-container .calibrate, .invite-section-container .invite-container .benchmark, .invite-section-container .invite-container .assess { width: auto; }
  // .invite-section-container .cta-btn a { display: flex; justify-content: center; }
}