@import './Assets/Styles/Variables.scss';
@import './Assets/Styles/Fonts.scss';
@import './Pages/Start/Start.scss';
@import './Pages/Intro/Intro.scss';
@import './Components/CallInfo/CallInfo.scss';
@import './Pages/FirstForm/FirstForm.scss';
@import './Pages/AssessmentV3/AssessmentV3.scss';
@import './Pages/AssessmentAdditional/AssessmentAdditional.scss';
@import './Pages/AssessmentAdditionalV3/AssessmentAdditionalV3.scss';
@import './Pages/Invite/Invite.scss';
@import './Pages/Tabs/Tabs.scss';
@import './Pages/Details/Details.scss';
@import './Pages/PreAssessment/PreAssessment.scss';
@import './Components/Promo/Promo.scss';
@import './Pages/Results/Results.scss';
@import './Pages/InviteThankYou//InviteThankYou.scss';
@import './Pages/Dashboard/Dashboard.scss';
@import './Components/Layout/Header/Header.scss';
@import './Pages/Chart/Chart.scss';
@import './Pages/Invite/Invite.scss';
@import './Components/InviteInfo/InviteInfo.scss';
@import './Components/Testimonial/Testimonial.scss';
@import './Pages/PreAdditionalInfo//PreAdditionalInfo.scss';
@import './Pages/PreAdditionalQuestions//PreAdditionalQuestions.scss';
@import './Components/ProgressBar/ProgressBar.scss';
@import './Components/UrgencyScore/UrgencyScore.scss';
@import './Components/QuestionInfo/QuestionInfo.scss';
@import './Pages/Explainer/Explainer.scss';





@import url("https://use.typekit.net/fvk4nfp.css");
// @import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,300;0,400;0,700;1,300;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,300;0,400;0,700;1,300;1,700&family=Montserrat:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,600;0,700;1,400;1,600;1,700&display=swap');




$color1: #009DF1;

$color1opacity30: #009DF130;
$color1opacity42: #009DF142;
$color1opacity60: #009DF160;

$color2: #76CEFD;
$color3: #343333;

$primaryfont: "Lato", sans-serif;
// $primaryfont: "Barlow", sans-serif;

// body {  background-image: url(./Assets/Images/assessment-background.png); background-size: cover; width: 100%; height: 100%; font-family: $primaryfont; color: $color3; margin: auto; overflow-x: hidden; background-attachment: fixed }

body { background-color: #004F88; }

.desktop { display: block; }
.mobile { display: none; }
html { height: 100%; }
.App { display: flex; flex-direction: column; min-height: 100vh; }

main {
  .logo-left-container{
    & { position: absolute; height: 252px; width: 92px; left: 0px; top: 50%; transform: translateY(-50%); z-index: -1; }
    .logo-left { & {}
      img { height: 252px; width: 92px; }
    }
    img { height: 252px; width: 92px; }
}
  .assessment-progress-sections-container { & { margin: 0 auto 60px; text-align: center; position: relative; color: #FFFFFF; max-width: 1100px; }
    .main-sections-dots { & { position: relative; max-width: 1100px; height: 3px; background-color: #FFFFFF; margin: 0 auto; display: flex; justify-content: space-between; }
      .main-section { & { position: relative; }
        .main-section-dot { position: relative; bottom: 5px; margin: 0 auto; width: 10px; height: 10px; background-color: #FFFFFF; border: 2px solid black; border-radius: 50%; }
        .main-section-dot.finished { box-sizing: border-box; width: 25px; height: 25px; bottom: 12px; background-color: #FDBF04; display: flex; justify-content: center; align-items: center; }
        .main-section-dot.active { box-sizing: border-box; height: 25px; width: 25px; bottom: 12px; background-color: #FFFFFF; background-image: radial-gradient(white 40%, #FDBF04 40%); }
        .main-section-dot.unfinished { box-sizing: border-box; height: 25px; width: 25px; background-color: #E9E9E9; bottom: 12px; border: 2px solid black; }
      }
    }
    .main-sections-names { & { position: relative; max-width: 1100px; height: 3px; margin: 0 auto; display: flex; justify-content: space-between; }
      .main-section { & { position: relative; width: 10ch; }
        .main-section-name { position: relative; margin-top: 20px; text-align: center; text-transform: uppercase; }
        .main-section-name.finished { color: #FFFFFF; font-weight: 400; }
        .main-section-name.active { color: #FDBF04; font-weight: 700; }
        .main-section-name.unfinished { color: #FFFFFF; font-weight: 300; }
      }
    }
  }


//  .container { max-width: 880px; margin: 20px auto ; border-radius: 10px ; box-shadow: 0 1px 20px 0 rgb(0 0 0 / 10%); } 
  .loading { display: flex; justify-content: center; align-items: center; }
  
  // .container.start { width: 95%; }
 


  .progress-percent-text { transition: 0.4s linear; font-family: $primaryfont; font-size: 18px; font-weight: 700; color: #ED2028; margin-top: 10px; margin-bottom: 0; line-height: 25px; width: 3ch; max-width: calc(100% - 2ch); }
  .current-section { font-family: $primaryfont; font-size: 21px; font-weight: 700; line-height: 25.2px; letter-spacing: 0px; text-align: center; text-transform: uppercase; color: #004F88; margin: 30px 0px; }

  .section-progress-top { max-width: 850px; padding-top: 10px; width: 95%; margin: 10px auto 5px; }



  .pre-additional-info, .pre-additional-questions-details{
    .step-buttons { & { display: flex; justify-content: space-around; align-items: center; margin: 45px auto; padding: 20px 15px; }
      .cta-prev { padding: 11px 25px; color: #182036; font-size: 18px; font-weight: 600; font-family: $primaryfont; }
      // .cta { margin: 0; font-size: 16px; color: #182036; padding: 11px 25px; font-family: $primaryfont; }
      .cta { display: block; border-radius: 5px; color: #182036; text-decoration: none; width: auto; text-align: center; font-family: $primaryfont; font-size: 18px; font-weight: 600; text-transform: uppercase; padding: 7px 18px; margin-left: 0; transition: 0.3s;height:50px; }
      .cta.Mui-disabled { background-color: #D8D8D8; color: #979797; }
      // .cta:hover { background-color: #435B63; transition: 0.3s; color: #fff; }
      // .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
      // .nextbtn:hover { background-color: $color2; }
      // .nextbtn.Mui-disabled { background: #C1C1C1; }
      .all-set-btn { font-size: 21px; /* padding: 7px 30px; */color: #182036; }
    }
    .end-note{font-weight: 400; font-style: italic; font-size: 16px; line-height: 34px; text-align: center; color: #343333;}
  }





  .team-container {
    & { max-width: 90%; width: auto; border-radius: 10px; margin: 50px auto; font-family: $primaryfont; font-size: 30px; line-height: 43px; color: #ffffff; text-align: center; font-weight: 400; }
    h3 { font-size: 30px; line-height: 43px; font-weight: 400; margin: 30px auto 0; color: #ffffff; }
    .cta-btn {
      & { width: 220px; background: $color1; margin: 40px auto 0; color: #fff; padding: 5px 0; border-radius: 25px; }
      &:hover { background: $color2; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: $primaryfont; font-size: 18px; font-weight: bold; }
    }
  }

  .dashboard-qa-container { 
    & { background-color: #F4F4F4; padding: 5px 15px; display: flex; justify-content: space-between; align-items: center; min-height: 45px; font-family: $primaryfont; }
    &:nth-child(odd) { background-color: #FBFBFB; }
    .dashboard-question { margin: 2px auto 2px 0; font-size: 16px; line-height: 21px; color: #27282A; text-align: left; width: 75%; }
    .dashboard-answer { margin: 2px 0 2px auto; font-size: 16px; line-height: 21px; font-weight: 700; text-transform: uppercase; text-align: right; }
  }


  .results .charts-accordions-container .MuiAccordionDetails-root { padding: 30px 60px; background-color: #FDF8F4; color: #100F2E; }
  .results .charts-accordions-container .accordion-chart-container { margin: 15px auto 0; }
  

  // .testimonial-container {
  //   & { max-width: calc(880px - 4%); margin: 50px auto; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: #1F2A44; display: flex; }
  //   .avatar { margin-right: 53px; }
  //   .testimonial { 
  //     & { position: relative; color: #fff; font-family: 'lato', sans-serif; font-size: 21px; line-height: 1.35em; }
  //     .quotation-mark { position: absolute; margin-top: -50px; }
  //     .designation { 
  //       & { color: $color2; font-weight: 700; padding-top: 20px; }
  //       span {  font-weight: 400; } 
  //     }
  //   }
  // }
 

  .testimonial-invite {
    & { font-family: $primaryfont; background-color: $color3; margin: 2rem; margin-top: 0; padding: 3rem; color: #fff; border-radius: 10px; font-size: 1.5rem; }
    p { max-width: 46ch; margin: 1rem auto 2rem auto; }
    .cta-btn { margin: 3rem auto 2rem auto; }
  }


  .invite-legacy-container {
    & { display: flex; justify-content: center; align-items: center; margin: 0 auto; }
    .invite-legacy { & { width: 96%; max-width: 1000px; box-sizing: border-box; background-color: #FFF; margin: 15px auto; padding: 20px 20px 20px; color: #343333; text-align: center; border-radius: 10px; }
      // p { margin: 0 auto; max-width: 45ch; padding: 0 30px; }
      h1 { font-size: 24px;line-height: 28px; }
      p { font-family: $primaryfont; line-height: 1.17em; max-width: 60ch; margin: 30px auto 15px; font-weight: 400;font-size: 21px; padding: 0 2rem; }
      .cta-btn {
        & { width: 100%; max-width: 250px; background: #FC2B38; margin: 35px auto 30px; color: #fff; border-radius: 5px; transition: 0.3s; }
        &:hover { background: #0E435C; color: $color3; transition: 0.3s; color: #fff; }
        a { display: block; text-decoration: none; color: #fff; }
        button { text-transform: uppercase;letter-spacing: 1px; width: 100%; color: #fff; font-family: $primaryfont; font-size: 16px; border-radius: 3px; font-weight: 700; padding: 11px 10px; }
      }
      .steps { font-size: 21px; line-height: 34px; font-weight: 400; max-width: 50ch; margin: auto; text-align: left;}
    }
  }

}

.btn { & { } }

footer { & { min-height: 100px; margin: 0 auto; text-align: start; padding: 12px; box-sizing: border-box; background: #FFFFFF; margin-top: auto; width: 100%; }
  .bleat-logo-mobile { display: flex; justify-content: center; align-items: center; width: 100% !important; } 
  .bleat-logo { display: flex; justify-content: center; align-items: center; width: 100% !important; }
  .bleat-logo img { height: 50px; width: auto; }
  .bleat-logo-max-padding img { height: 85px; width: 80px; padding: 10px 12px 100px 12px; }
}

.results, .dashboard {
  .hurdles-enablers {
    & { width: 95%; max-width: 777px; margin: 15px auto 15px; }
    h2 { font-family: $primaryfont; font-weight: 700; font-size: 18px; line-height: 25px; text-align: left; margin: 15px auto; color: #202945; text-transform: uppercase; }
    .hurdles { background: rgba(210, 62, 37, 0.099);  padding: 10px 4% 1px 2%; font-family: $primaryfont; font-size: 16px; line-height: 1.5em; color: $color3; text-align: left; border-radius: 13px; }
    .enablers { background:rgba(115, 183, 111, 0.0997);;  padding: 10px 4% 1px 2%; font-family: $primaryfont; font-size: 16px; line-height: 1.5em; color: $color3; text-align: left; border-radius: 13px; }
    li { color:#4A4A4A; padding-bottom: 10px; font-weight: 400; font-size: 16px; line-height: 21px; }
  }
}


  
  .label-name { font-size: 14px; font-weight: 600; margin: 2px auto; }
  .label-percent { font-size: 18px; font-weight: 700; margin: 2px auto; }
  

.accordions-section-container, .invite-section-container {
  & { padding: 30px 15px; margin: 45px auto; width: 95%; max-width: 1340px; box-sizing: border-box; background-color: #FFF; border-radius: 10px; }
  h1 { text-align: center; text-transform: uppercase; }
  h2 { text-align: center; }
}


@media only screen and (min-width: 650px) {

  // main .assessment-additional .steps .slider-container .slider-labels br { content: ""; }
  // main .assessment-additional .steps .slider-container .slider-labels br:after { content: " "; }

}

@media only screen and (max-width: 1250px) { 
  
  .custom-slider.MuiSlider-root { padding: 14px 0; }  

}

@media only screen and (max-width: 1110px) {

  main .dashboard  { overflow: hidden; }
  main .testimonial-container .testimonial .testi br { content: ""; }
  main .testimonial-container .testimonial .testi br:after { content: " "; }

}



@media only screen and (min-width: 831px) {
  // main .bleat-logo-mobile { display: none; }
  // main .container.start { margin: 30px auto; }
  
}

@media only screen and (max-width: 830px) {

  main .current-section { font-size: 16px; margin: 10px auto 30px; font-weight: 700; line-height: 19.2px; color: #100F2E; }

  main .results .charts-accordions-container, main .dashboard .charts-accordions-container {
    padding: 0 0 45px;
  }
  .label-name { font-size: 14px; margin: 2px auto; }
  .label-percent { font-size: 16px; margin: 2px auto; }

  footer { & { min-height: 0; width: 100%; left: 0; }
  .bleat-logo{
    &{display:flex; justify-content: center;}
  }
    .bleat-logo-mobile { & { width: 100%; display: flex; justify-content: center; }
      img { height: 70px; width: auto; }
    }
  }
  
  main {
    .container { margin: 24px auto; display: flex; flex-direction: column; }
    .results .result-text, .dashboard .result-text { width: 95%; box-sizing: border-box; font-size: 20px; }
  }
  main .logo-left-container { display: none; }
  // main .start .video .thumbnail { width: auto; height: 100%; }
  main .assessment .steps {
    .progress-percent-text { display: none; }
    .question { font-size: 21px; }
    .progress-bar { 
      // & { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1rem; }
      & { display: flex; justify-content: center; }
      .bar-section { width: 90% !important; }
    }
    .current-section { margin: 10px auto 0; }
    .progress-indicator { margin: 50px auto; }
    .question { align-items: flex-start; }
    .slider-container .slider-labels { & { font-size: 16px; }
      .label { max-width: 10ch; }
      .label p { font-size: 14px; }
    }
    .radio-container .slider-labels { & { font-size: 16px; }
      // .label { max-width: 10ch; }
    }
  }

  main .details .form, main .pre-additional-questions-details .form { width: 85%; }
  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 16px; }
  main .results .selection-form { & { font-size: 28px; }
    .form-details-text .MuiInput-root { font-size: 28px; }
  }
  main .dashboard {
    table { & { font-size: 15px; }
      thead th { font-size: 14px; }
    }
  }

  



}



@media only screen and (max-width:1065px) {
  main .results .score-section-container, main .dashboard .score-section-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 650px) {

  // main .dashboard .urgency .urgency-slider .slider-labels .label p br { content: ""; }
  // main .dashboard .urgency .urgency-slider .slider-labels .label p br:after { content: " "; }

}

@media only screen and (max-width: 650px) {

  // main .start .video .playbtn { width: 144px; height: 144px; }
  main .assessment .mobile-spacer { display: none; }
  main .assessment .steps .progress-bar {
    .bar-section .progress-percentage { font-size: 1rem; margin: 1rem 0 0.5rem 0.75rem; }
    .sections .section-name { font-size: 15px; }
  }
  main .pre-additional-info { & { padding: 15px; } h1 { font-size: 21px; } p { font-size: 18px; } }
  main .details{
    .form { & { width: 90%; } 
    .form-detail { & { flex: 0 1 100%; max-width: 100%; }
      .input-title { max-width: 100%; }
      .error-text { margin: 0 auto -10px; }
    }
    }
  }
  main .results, main .dashboard {
    .result-sections .score-section { font-weight: 400; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: -15px; }

  }
  main .results, main .dashboard { & {  }
    .pervalue { font-size: 14px; top: -65px; }
    .si_pro_header { bottom: 85px; }
    .pervalue-text { & { bottom: 80px; margin: 10px auto; position: relative; width: 100%; }
      p { display: inline-block; text-align: center; margin: 0 auto; font-size: 13px; color: #4A4A4A; }
    }
    .result-text { font-size: 18px; padding: 15px 18px; }
    .score-section-container {
      // & {grid-template-columns: repeat(1, 1fr);grid-template-rows: repeat(1, 1fr);flex-wrap: wrap; padding: 0 0 20px 0; max-width: 90%; margin-top: 0; }
      & { grid-template-columns: repeat(1, 1fr);grid-template-rows: repeat(1, 1fr); }
      .score-section { 
        & { margin: 15px auto; width: 100%; max-width: 260px; }
        .score-txt { font-size: 18px; font-weight: 700; margin: 15px auto; }
        .score-name { font-size: 18px; font-weight: 700; min-width: 12ch; margin: 0.25rem auto; }
        .score-slider { width: 90%; margin: 0 auto 15px; max-width: 250px; }
        
      }
    }
  }

  main .testimonial-container .testimonial .quotation-mark img { height: 18px; width: auto; }
  main .testimonial-container .testimonial { & { color: #fff; margin: 20px auto 0; text-align: center; }
    .quotation-mark { color: $color1; font-size: 46px; }
    .testi { font-size: 18px; }
    .designation { color: #FFF; 
      span { color: #fff; font-size: 18px; }
    }
  }
  main .details h1, main .pre-additional-questions-details h1 { font-size: 28px; }
  main .testimonial-invite {
    & { background-color: #DCDBDB; margin: 0 auto; padding: 3rem 2rem; color: #27282A; border-radius: 10px; font-size: 21px; font-weight: 700; }
    p { max-width: 40ch; margin: 1rem auto; line-height: 43px; }
    .cta-btn { margin: 2rem auto 0rem auto; }
  }
  
  main .invite-legacy-container .invite-legacy { & { padding: 3rem 1rem; }
    .cta-btn { margin: 2rem auto 0 auto; }
  }

  main .invite-legacy-container .invite-legacy { padding: 0.5rem 1rem 2rem;}

  .results .charts-accordions-container .MuiAccordionDetails-root { padding: 10px 20px !important;}
}


@media only screen and (max-width: 550px) {
  
  .assessment-title {
    .logo-text { font-size: 22px; line-height: 26px; }
    .logo-subtext { font-size: 16px; line-height: 21px; }
  }

  main .assessment .steps .question { font-size: 18px; line-height: 24px; }

  main .pre-additional-info .step-buttons, main .pre-additional-questions-details .step-buttons, main .assessment-additional .step-buttons { padding: 15px 15px 30px; max-width: 90%; }
  main .assessment-additional .steps { margin: 0 auto 30px; }

  // main .assessment .steps .slider-container .custom-slider.MuiSlider-root { width: calc(100% + 25px); margin-left: -6px; }
  main .assessment .steps .slider-container .slider-labels .label p { width: 145%; margin-left: -75%; letter-spacing: 0px; }

  main .details { & { width: 95%; margin-top: 15px; }
    h1, main .pre-additional-questions-details h1 { font-size: 24px; }
    p { padding: 0 5%; font-size: 18px; }
    p br { content: ""; }
    p br:after { content: " "; }
    .form, main .pre-additional-questions-details .form { width: 90%; }
    .form .form-detail .form-details-text { padding-right: 0; max-width: 100%; }
    .details-buttons { & { max-width: 90%; display: flex; flex-direction: column-reverse; }
      .back-btn { align-self: flex-start; margin-top: 2rem; }
      .cta-btn { & { width: max-content; margin: 0 auto; text-align: center; }
        button { width: 350px; padding: 4px 1rem; } }
    }
  }
  // main .details .form .form-detail .form-details-text.react-select { max-width: 97.5%; }

  main .invite-legacy-container .invite-legacy {
    h1 { font-size: 21px; line-height: 28px; margin: 20px auto 30px; }
    p { font-size: 18px; line-height: 24px; }
  }

  

  main .team-container { width: 90%; font-size: 18px; }
  main .team-container h3 { font-size: 24px; line-height: 38px; }

  main .schedule-container { width: 85%; font-size: 18px; }
  main .schedule-container h3 { font-size: 24px; }
  // main .testimonial-container { flex-wrap: wrap; padding-top: 12px; }
  // main .testimonial-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }

  main .testimonial-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }
  main .testimonial-container .testimonial .quotation-mark { font-size: 46px; }
  main .testimonial-container .testimonial .designation { font-size: 26px; }
  main .testimonial-container .testimonial .designation span { padding-top: 6px; }
  main .testimonial-container .testimonial .testimonial-para { text-align: center; }
  main .testimonial-container .testimonial .name-description { text-align: center; }
  
  main .invite-legacy-container .invite-legacy { & { font-size: 18px; margin: 15px 12px; }
    p { padding: 0 15px; }
    .steps { font-size: 18px; line-height: 28px;}
  }

  main .testimonial-container .testimonial { font-size: 18px; width: 90%; margin: 0px auto 0; text-align: center; }

  main .results .selection-form .form-details-text .MuiInput-root { font-size: 24px; }
  main .dashboard .alignment .content .align-details.analyze .answered-detail { flex-direction: row; }
  main .results, main .dashboard {
    .hurdles-enablers {
      h2 { font-size: 16px; }
      li { font-size: 16px; }
    }
    
  }


}

@media only screen and (max-width: 550px) {

  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 14px; margin-bottom: 31px; }  
  main .start .intro .completion { margin: 20px auto 0; width: 100%; justify-content: center; }
  main  .completion .cta { margin: 0 auto; width: 90%; }

    .cta {
      .cta-btn { & { line-height: 25px; font-size: 21px; width: auto; margin: 75px auto 45px; padding: 7px 15px; }
        // &:hover { background: #435B63; transition: 0.3s; color: #fff; }
      }
    
  }
  main .results .pervalue { font-size: 12px; top: -47px; }
  main .results .si_pro_header { bottom: 67px;}
  main .results .si_pro_header h4:before { height: 16px !important; }
  main .results .pervalue-text { bottom: 80px;}

  footer .bleat-logo-mobile img { height: 45px; } 
}

@media only screen and (max-width: 450px) {
  // main .dashboard table { font-size: 11px; }
  // main .dashboard table .footer .church-average { font-size: 11px; }

}

// Tabs CSS


// .tabs {
//   padding: 15px 0px;
//   text-align: center;
//   color: white;
//   width: 100%;
  
//   cursor: pointer;

//   box-sizing: content-box;
//   position: relative;
//   outline: none;
// }
// .active-tabs {
//   color: #000;
//   background: $color1;
//   border-bottom: 1px solid transparent;
//   &::before {
//     content: "";
//     display: block;
//     position: absolute;
//     top: -5px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: calc(100%);
//     height: 0px;
//     background: rgb(88, 147, 241);
//   }
// }
// button {
//   border: none;
// }

