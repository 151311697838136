
// main .individual-group {
//   & { max-width: 400px; width: 376px; margin: 30px auto 45px; display: flex; height: 49px; border-radius: 5px; background-color: #ffffff;  }
//   button { cursor: pointer; flex: 0 1 50%; border: 0px; background: white; color: #1F2A44; font-family: $primaryfont; font-size: 18px; font-weight: 600; padding: 10px 0; text-align: center; border: 2px solid #FFF; }
//   .left { border-top-left-radius: 1px; border-bottom-left-radius: 1px; font-weight: 400; transition: all  ease-in-out 0.2s;border-radius: 5px 0px 0px 5px; }
//   .left:hover { /* background-color: #435B63; color: #ffffff; */ transition: ease-in-out 0.2s; border-color: #0E435C; }
//   .right { border-top-right-radius: 1px; border-bottom-right-radius: 1px; font-weight: 400; transition: all  ease-in-out 0.2s;background-color: #FFFFFF; border-radius: 0px 5px 5px 0px; }
//   .right:hover { /* background-color: #435B63; color: #ffffff; */ transition: ease-in-out 0.2s; border-color: #0E435C; }

//   .active { background-color: #FC2B38; color: #ffffff; font-weight: 700; border-color: #FC2B38; border-radius: 5px;}
// }

main .individual-group {
  & { max-width: 400px; width: 376px; margin: 30px auto 45px; display: flex; height: 49px; border-radius: 25px; background-color: #ffffff; overflow: hidden; font-family: $primaryfont; }
    button { cursor: pointer; flex: 0 1 50%; border: 0px; background: white; color: #1F2A44; font-family: $primaryfont; font-size: 18px; font-weight: 600; padding: 10px 0; text-align: center; }
    .left { border-radius: 25px  25px; font-weight: 400; transition: all ease-in-out 0.2s; text-transform: uppercase; }
    .right { border-radius:  25px 25px 0; font-weight: 400; transition: all ease-in-out 0.2s; background-color: #FFFFFF; text-transform: uppercase; }
    .left:hover, .right:hover { transition: ease-in-out 0.2s; opacity: 20px; }
    .active { background-color: #8CC63F; color: #ffffff; font-weight: 700; }
}

  .accordions-section-container {
    .toolkit-container { & { display: flex; justify-content: center; align-items: center; flex-direction: column; }
      .toolkit { & { display: flex; align-items: center; flex-direction: column; border: 1px solid #004f88; width: 80%; margin: 4px 0; border-radius: 17px; }
        .toolkit-head { & { display: flex; justify-content: space-between; background-color: #004F88; color: #ffffff; width: 100%; height: 48px; box-sizing: border-box; border-radius: 15px; }
          p { margin: 0; line-height: 48px; font-weight: 700; font-size: 20px; padding-left: 15px; }
        }
        
      }
    }
  }

main .toggle-individual-group { display: none; }

.dashboard {
  & { min-height: 630px; background-color: #fff; width: 96%; }
  .details-container { padding: 0 10px 0; }
  // h1 { width: auto; text-align: center; margin: 0 auto; font-family: $primaryfont; font-size: 30px;font-weight: 700;line-height: 38px; color: $color3; padding: 45px 30px 24px; max-width: 48ch; }
  h1 { width: 654px; text-align: center; margin: 0 auto; font-family: $primaryfont; font-size: 30px;font-weight: 700;line-height: 38px; color: #555555; padding: 45px 30px 24px; max-width: 48ch; }
  
  .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
  span.msi_name { color: #FC2B38; font-size: 18px; font-weight: 900; font-family: $primaryfont; }
  .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
  .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
  .in-ass-result .si_ass_progress span, .single-assessment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: $primaryfont; }
  .si_ass_progress:before {  }
  .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

  .si_ass_progress { position: relative; max-width: 700px; height: 26px; margin: 0 auto; border-radius: 12px; width: 100%; 
    background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%);
    // background-image: linear-gradient(to right, #cc2c24 69.5%, #E98526 70% 79.5%, #FFCA27 80% 94.5%, #4E9A51 95% 100%);
  }
  .si_ass_progress .progress-line { width: 2px; height: 35px; content: ' '; background: $color3; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
  .si_ass_progress .progress-line .progress-text { position: absolute; top: -58px; left: -100px; width: 200px; height: 30px;
    color: #fdbf04; font-size: 16px; font-weight: 600; font-family: $primaryfont;  
  }
  .si_ass_progress .msi_score { z-index: 2; background-color: #004F88; border-radius: 8px; padding: 5px 5px; display: block; position: absolute; font-size: 27px; font-weight: 700; color: #fff; bottom: 50px; left: 0; margin-left: -30px; min-width: 45px; text-align: center; }
  .si_ass_progress .score-arrow { z-index: 1; width: 20px; height: 20px; content: ' '; background-color: #004F88; left: 0; bottom: 45px; position: absolute; margin-left: -10px; transform: rotate(-45deg); }
  .si_ass_progress .transparent { background-color: #ffffffbb; height: 100%; margin-left: auto; border-top-right-radius: 12px; border-bottom-right-radius: 12px;}
  
  // .si_ass_progress .msi_score::after { display: block; margin: 0 33% 1rem 33%; content: ""; height: 50px; background-color: black; width: 2px; color: black; }
  .pervalue { max-width: 700px; margin: -68px auto; display: flex; clear: both; color: #004F88; font-size: 16px; }
  // .pervalue h4.nimp:after { content: '70%'; position: absolute; right: -19px; }
  // .pervalue h4.mexpe:after { content: '80%'; position: absolute; right: -19px; }
  // .pervalue h4.eexpe:after { content: '95%'; position: absolute; right: -19px; }
  
  // .result-sections { 
  //   & { width: 95%; max-width: 800px; margin: 50px auto; margin-bottom: 0; display: flex; justify-content: center; flex-wrap: wrap; }
  //   .score-section { 
  //     & { flex: 0 1 25%; text-align: center; font-weight: 700; font-size: 18px; margin: 10px auto; }
  //     .score-txt { font-size: 18px; font-weight: 700; margin-bottom: 6px; }
  //     .score-slider { width: 90%; margin: 0 auto; margin-bottom: 16px; }
  //   }
  // }

  .pre-score-header { line-height: 28px; text-align: center; color: #555555; font-family: $primaryfont; font-size: 21px; font-weight: 400; margin: auto auto; max-width: 44ch; }
  // .result-text { text-align: center; font-size: 24px; margin: 20px auto; max-width: 65ch; background-color: #FFFFFF; padding: 5px 30px; border-radius: 10px; color: #121212; }

}
.score-section-container { & { display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-evenly; align-items: center;  margin: 60px auto 0 auto; width: 100%; }
  .rowdata { & { display: flex; width: 100%; justify-content: center; align-items: center; color: #555555; } 
  .colnname { width: 28%; font-size: 16px; font-weight: 400; line-height: 21.6px; color: #555555; margin: 4px 0; text-transform: capitalize; font-family: $fontLato; }
  .colscore { & { width: 52%; height: 20px; background: transparent; display: flex; align-items: center; margin-left: 10px; }
    div { height: 20px; border-left: 2px; border-radius: 6px 10px 10px 6px; }
    h3 { margin: 0px 8px; font-size: 18px; font-weight: 400; font-family: $primaryfont; }
  }
}
  .result-sections { 
    & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; padding-bottom: 50px; }
  }
  

  .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 5px; }
  .custom-bar .MuiLinearProgress-bar { background-color: $color1; }

  table { & { font-family: $primaryfont; border-collapse: collapse; width: 90%; max-width: 777px; margin: 0px auto 60px; font-size: 18px; font-weight: 400; color: $color3; }
    tr td:first-child { font-weight: 700; }
    tr:nth-child(even) { background-color: #F4F4F4; }
    td, th { border: 1px #dddddd;text-align: left;padding: 8px; }
    td{font-size: 18px;color: #4A4A4A;}
    thead th { font-size: 14px; line-height: 17px; font-weight: 700; color: #373737; }
    thead th:last-child{font-weight: 700;}
    th.center-cell:last-child, td.center-cell:last-child { font-weight: 700;color: #4A4A4A; }
    .center-cell { text-align: center; text-transform: capitalize;line-height: 17px;  }
    .footer .center-cell{font-size: 18px; font-weight: 700; color: #FC2B38 !important;; }
    .medium { line-height: 22px;font-style: normal;color: #4A4A4A; }
    .hash { width: 13px; }
    .avg { text-align: center; }
    .header { border-style: none; border-bottom: 3px solid #0E435C; }
    .footer { 
      & { border-style: none; border-top: 3px solid #0E435C; color: #435B63; }
      .church-average { font-size: 14px; ;color: #373737;font-weight: 700; }
    }
  }

  hr { width: 96%; color: #979797; }

  h2 { font-family: $fontLato; font-size: 24px; font-weight: 700; color: #27282A; line-height: 38px; margin-top: 50px; text-align:center; text-transform: uppercase; }
  .most-answered {
    & { width: 96%; max-width: calc(777px); background: #FBFBFB; margin: 30px auto; padding: 0px 0% 0px; font-family: $primaryfont; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 10px; overflow: hidden; }
    // .green { background-color: #73B76F; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 10px;  border-top-right-radius: 10px; }
    .green { background-color: #73B76F; color: #FFFFFF; padding: 15px 17px; margin: 0; font-weight: 700; border-radius: 10px 10px 2px 2px; }
    // .red { background-color: #FF6645; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 10px;  border-top-right-radius: 10px; }
    .red { background-color: #FF6645; color: #FFFFFF; padding: 15px 17px; margin: 0; font-weight: 700; border-radius: 10px 10px 2px 2px;} 
    .most-answered-detail { font-weight: 400; font-size: 18px; line-height: 22px; color: #000000; padding: 10px 15px; width: 100%; font-family: $primaryfont; box-sizing: border-box; }
    .most-answered-detail:nth-child(even) { background-color: #f4f4f4; }
    .most-answered-detail { display: flex; flex-direction: row; }
    .most-answered-detail .list-item-number { padding: 0 5px 0 5px; }
    .most-answered-detail .list-item-text {  }
  }

  .alignment { 
    & { width: 96%; max-width: 777px; margin: 0 auto 30px; background-color: $color1; margin: 50px auto 0; border-radius: 10px; }
    .container-tabs { display: flex; flex-direction: column; position: relative; width: 100%; min-height: 150px; background-color: #EEEEEE; border-radius: 10px; }
    .tab-buttons { 
      & { display: flex; width: 100%; margin: 0 auto; justify-content: space-between; background-color: #EEEEEE; border-radius: 10px 10px 0px 0px; padding: 10px 8px 0; box-sizing: border-box; }
      button { margin: 3px 2px -1px; font-family: $fontLato; color: #585252; font-size: 18px; font-weight: 600;line-height: 22px; text-transform: uppercase; text-align: center; padding: 15px 20px; background: none; border: 0px; min-width: 24%; cursor: pointer; transition: all ease-in-out 0.2s; border-radius: 10px 10px 0 0; }
      button:hover { color: #ffffff; background-color: #0E435C; transition: all ease-in-out 0.2s; border-radius: 10px 10px 0 0; }
      .active { color: #ffffff;background-color: #0E435C; font-weight: 700;font-size: 18px;line-height: 22px; border-radius: 10px 10px 0 0;}
    }
    .tabs-content { flex-grow: 1; background-color: #0E435C; padding: 5px; border-radius: 8px; }

    .content { 
      & { width: 100%; display: none; }
      .align-details {
        & { max-width: 99%; background: #FBFBFB; margin: 10px auto; padding: 0px 0% 0px; font-family: $primaryfont; font-size: 18px; text-align: left; border-radius: 10px; overflow: hidden;}
        // & { max-width: 99%; background: #FBFBFB; margin: 10px auto; padding: 0px 0% 0px; font-family: $primaryfont; font-size: 18px; text-align: left; border-radius: 10px; overflow: hidden; }
        // .green { background-color: #73B76F; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-radius: 10px 10px 0 0;}
        .green-container { display: flex; justify-content: space-between; background-color: #73B76F; align-items: center; }
        .green { color: #FFFFFF; padding: 15px 17px; margin: 0; font-weight: 700; border-radius: 9.71539px 9.71539px 1.94308px 1.94308px; line-height: 21px; }
        .celebrateCurrentAreabtn { color: #FFFFFF; padding-right: 17px; }
        .red-container { display: flex; justify-content: space-between; background-color: #FF6645; cursor: pointer; align-items: center; }
        .red {color: #FFFFFF; padding: 15px 17px; margin: 0; font-weight: 700; border-radius:9.71539px 9.71539px 1.94308px 1.94308px; line-height: 21px;}
        .analyzePotentialAreabtn { color: #FFFFFF; padding-right: 17px; }
        // .red { background-color: #FF6645; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-radius:9.71539px 9.71539px 1.94308px 1.94308px; line-height: 21px;}
        // .red { background-color: #FF6645; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px;}
        .answered-detail { 
          & { display: flex; flex-wrap: wrap; align-items: center; font-weight: 400; font-size: 18px;line-height: 16px; color: $color3; padding: 10px 15px; width: calc(100% - 30px); }
          .answer { text-align: right; font-weight: 400; font-size: 14px; line-height: 1; text-transform: uppercase; min-width: 15ch; max-width: 20ch; }
          .true { color: #73B76F; }
          .false { color: #FF6645; }
          .neutral { color: #FDBF04; }
          .exception { color: #0E435C; }
          p { flex: 0 1 94%; font-weight: 400; line-height: 24px; font-size: 16px; margin: 2px auto; }
          .more-toggle { display: block; flex: 0 1 6%; cursor: pointer; color: #0E435C; }
          .more-details { 
            & { display: none; width: 91%; margin-left: 5%; padding: 5px 2%; border-left: 3px solid #0E435C; font-size: 14px; }
            .more-row { 
              & { display: flex; align-items: center; }
              p { min-width: 14ch; max-width: 20%; padding: 5px; margin: 0;font-weight: 400; font-size: 14px;line-height: 16px;  }
              .more-answer { text-transform: uppercase; font-weight: bold; }
            }
          }
        }
        .no-wrap { flex-wrap: nowrap; }
        .answered-detail { font-size: 16px; line-height: 24px; font-weight: 400; display: flex; justify-content: space-between; }
        .answered-detail:nth-child(even) { background-color: #f4f4f4; }
      }
      
    }
    .active-content { display: block; }
  }

  .mobile_scores {
    & { display: none; width: 100%; margin: 0 auto; }
    .individual {
      h2 { font-size: 18px; font-weight: 700; }
      & { font-size: 21px; font-weight: 700;border-bottom: 2px solid #343333; }
      .row { 
        & { display: flex; align-items: center; border-top: 2px solid #343333; min-width: 350px; width: 95%; max-width: 95%; }
        &:last-of-type { border-bottom: 2px solid #343333; }
        .number { flex: 0 1 30px; font-size: 18px; }
        .name { flex: 1 1 auto; }
        .percent { font-size: 18px; margin-right: 10px; color: #343333; }
        .toggle { padding-top: 6px; color: #0E435C; }
      }
      .scores { 
        & { font-size: 18px; border-top: 2px solid #343333; }
        .section_rows { 
          & { display: flex; padding: 12px 16px; }
          &:nth-of-type(even) { background-color: #F4F4F4; }
          .section { flex: 1 1 auto; }
          .section_score { flex: 0 1 auto; font-weight: 400; }
        }
      }
    }
  }
}

.dashboard-page {
  .accordions-section-container {
    .toolkit-container { display: flex; justify-content: center; align-items: center; flex-direction: column;
      .toolkit { display: flex; align-items: center; flex-direction: column; border: 1px solid #004f88; width: 80%; border-radius: 17px;
        .toolkit-head { display: flex; justify-content: space-between; background-color: #004F88; color: #ffffff; width: 100%; height: 48px; box-sizing: border-box; border-radius: 15px 15px 0 0;  transition: border-radius 0.3s ease; 
          p { margin: 0; line-height: 48px; font-weight: 700; font-size: 20px; padding-left: 15px; }
          &.closed { border-radius: 15px; }
        }
        .toolkit-body { width: 100%; overflow: hidden; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;
          .toolkit-data { display: flex; justify-content: space-between; align-items: center; background-color: #D5EAF7; padding: 10px 15px; border-top  : 0.5px solid #004F88 ;
            p { margin: 0; color: #004F88; font-weight: bold; }
            button { background: none; border: none; cursor: pointer; padding: 5px;
              img { display: block; }
            }
          }
          .content-details { background-color: white; padding: 20px; border-top: 1px solid #e0e0e0; position: relative; }
          .ministry-label { position: absolute; top: 10px; left: 20px; font-size: 14px; font-weight: bold; color: #004F88; }
          .content-placeholder { margin-top: 40px; display: flex; flex-direction: column; align-items: center; }
          .content-placeholder > b { text-align: center; color: #555555; font-size: 20px; margin-bottom: 20px; }
          .usage-stats { width: 60%; padding: 10px; }
            .stat-row { display: flex; align-items: center; margin-bottom: 15px; font-size: 16px; }
              .tool-name { flex: 0 0 120px; margin-right: 10px; }
              .percentage-bar { height: 35px; border-top-left-radius: 7px; border-bottom-left-radius: 7px;    border-top-right-radius: 16px; border-bottom-right-radius: 16px; position: relative; display: flex; align-items: center; padding-right: 10px; }
              .percentage { margin-left: auto; color: white; font-weight: bold; }
              .multiplier { margin-left: 10px; font-size: 16px; font-weight: bold; }
              .user-icon { width: 20px; height: 20px; margin-left: 5px; }
        }
      }
    }
    .note-container {
      & { width: 80%; margin: 0 auto; padding: 13px 15px; background-color: #F5F5F5; margin-top: 15px; border-radius: 5px; }
      .note {
        & { font-family: $primaryfont; font-size: 18px; font-weight: 400; font-style: italic; color: #555555; line-height: 21.6px; }
        .note-head {
          & { font-family: $primaryfont; font-size: 18px; font-weight: 700; color: #555555; line-height: 21.6px; font-style: normal; }
        }
      }
    }
  }
}

main .results.individual {
  .score-section-container { & { display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-evenly; align-items: center;  margin: 60px auto 0 auto; width: 100%; gap: 10px; }
    .rowdata { & { display: flex; width: 100%; justify-content: center; align-items: center; color: #555555; } 
      .colnname { width: 28%; font-size: 16px; font-weight: 400; line-height: 21.6px; color: #555555; margin: 4px 0; text-transform: capitalize; }
      .colscore { & { width: 52%; height: 20px; background: transparent; display: flex; align-items: center; margin-left: 10px; }
        div { height: 20px; border-left: 2px; border-radius: 6px 10px 10px 6px; }
        h3 { margin: 0px 8px; font-size: 18px; font-weight: 400; }
      }
    }
  }
}

.dashboard {
  .progress-bar { & { margin: 60px auto 0px; max-width: 90%; display: block; }}
  .si_pro_header { max-width: 700px; margin: 10px auto 0; display: flex; clear: both; position: relative; bottom: 88px; }
  .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color:  $color3; text-transform: capitalize; margin-top: 10px; }
  .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; }
  .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
  .si_pro_header h4.eexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
  .si_pro_header h4.eexpe1:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; }
  .si_pro_header h4 span { color: $color1; line-height: 35px; font-size: 16px; }

  .pervalue h4 { position: relative; }
  .pervalue { max-width: 700px; margin: -68px auto; margin: 0 auto; display: flex; clear: both; color: #4A4A4A; font-size: 15px; position: relative; bottom: 65px; }
  // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: 35px; }
  // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: 35px; }
  // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: 35px; }
  // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: 35px; }
  .pervalue h4.nimp1:after { content: "0%"; position: absolute; right: -19px; font-weight: 800; top: 50px; font-family: $fontLato; font-size: 14px; font-weight: 700; color: #555555; line-height: 16.8px;}
  .pervalue h4.nimp:after { content: "50%"; position: absolute; right: -19px;  font-weight: 800; top: 50px; font-family: $fontLato; font-size: 14px; font-weight: 700; color: #555555; line-height: 16.8px;}
  .pervalue h4.mexpe:after { content: "80%"; position: absolute; right: -19px;  font-weight: 800; top: 50px; font-family: $fontLato; font-size: 14px; font-weight: 700; color: #555555; line-height: 16.8px;}
  .pervalue h4.eexpe:after { content: "100%"; position: absolute; right: -10px; font-weight: 800; top: 50px; font-family: $fontLato; font-size: 14px; font-weight: 700; color: #555555; line-height: 16.8px;}
  .pervalue h4.eexpe1:after { content: "70%"; position: absolute; right: -19px; font-weight: 800; top: 50px; font-family: $fontLato; font-size: 14px; font-weight: 700; color: #555555; line-height: 16.8px;}
  .pervalue h4.eexpe2:after { content: "100%"; position: absolute; right: -19px; font-weight: 800; top: 50px; font-family: $fontLato; font-size: 14px; font-weight: 700; color: #555555; line-height: 16.8px;}
  // .pervalue-text h4.nimp:after { content: 'Not Aligned'; position: absolute; right: -19px; bottom: -75px; }
  // .pervalue-text h4.mexpe:after { content: 'Needs Better Alignment'; position: absolute; right: -19px; bottom: -75px; }
  // .pervalue-text h4.eexpe:after { content: 'Aligned'; position: absolute; right: -19px; bottom: -75px; }
  .pervalue-text { & { position: relative; display: flex; margin: 0 auto; width: 100%; max-width: 700px; bottom: 73px; }
    p { font-family: $primaryfont; display: inline-block; text-align: center; margin: 0 auto; top: 12px; font-size: 18px; vertical-align: top; color: #575757; line-height: 16px; }
  }

  // .result-text { text-align: center; font-size: 18px; font-weight: 400; line-height: 28px; margin: 5px auto 15px; max-width: 48ch; background-color: #EEEEEE; padding: 25px 30px; border-radius: 10px; color: #121212; }
  .result-text { font-size: 18px; font-weight: 400; line-height: 28px; margin: 5px auto 15px;max-width:777px; background-color: #EEEEEE; padding: 30px; border-radius: 10px; color: #121212; width: 95%; box-sizing: border-box; }
  .result-text p { padding-top: 0; margin-block-start: 0; margin: 10px auto; }

  // .score-section-container { & { grid-template-columns: repeat(5, 1fr);grid-template-rows: repeat(1, 1fr); display: grid; flex-direction: row; justify-content: center; align-items: center; max-width: 600px; padding: 1rem 0 1rem; margin: 0rem auto; width: 95%; }
  .score-section-container { & { display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-evenly; align-items: center; max-width: 1500px; padding: 1rem 0 1rem;  width: 95%; }  
  .score-section { 
      & { display: flex ; flex-direction: column; flex: 0 1 22%;width: 184px; text-align: center; color: $color3; font-size: 18px; font-weight: 700; margin: 20px 10px; text-transform: uppercase; align-items: center; }
      .score-txt { font-size: 21px;line-height: 25px;font-weight: 700; color: #343333; font-family: $primaryfont; margin: 6px auto; max-width: fit-content; transition: all 0.4s linear; }
      .score-name { text-align: center; font-weight: 700; font-size: 16px; line-height: 22px; margin: 15px auto 20px; }
      .score-slider { width: 100%; margin: 0 auto; }
      .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 7px; border-radius: 10px; width: 100%; }
      .custom-bar .MuiLinearProgress-bar { background-color: #FF9045; }
    }
  }
  .charts-accordions-container {
    & { width: 96%; max-width: 860px; margin: 15px auto 30px; box-sizing: border-box; padding: 10px 10px 30px; }
    .MuiAccordion-root { color: #FFFFFF; margin: 10px auto; }
    .MuiAccordionSummary-root { background-color: #435B63; color: #FFFFFF; }
    .MuiAccordionDetails-root { padding: 15px 15px 45px; }
    .MuiAccordionSummary-content { & { color: #FFFFFF; font-size: 18px; line-height: 31px; margin: 5px auto; }
      p { margin: 0 auto 0 0; text-align: left; }
    }
    .MuiAccordionSummary-expandIconWrapper { color: #FFFFFF; }
    .accordion-chart-container { padding: 0px; box-sizing: border-box; margin: 15px auto; }
  }

  .hurdles-enablers {
    & { width: 95%; max-width: 777px; margin: 15px auto 15px; }
    h2 { font-family: $primaryfont; font-weight: 700; font-size: 18px; line-height: 25px; text-align: left; margin: 15px auto; color: #202945; text-transform: uppercase; }
    .hurdles { background: rgba(210, 62, 37, 0.099);  padding: 10px 4% 1px 2%; font-family: $primaryfont; font-size: 16px; line-height: 1.5em; color: $color3; text-align: left; border-radius: 13px; }
    .enablers { background:rgba(115, 183, 111, 0.0997);;  padding: 10px 4% 1px 2%; font-family: $primaryfont; font-size: 16px; line-height: 1.5em; color: $color3; text-align: left; border-radius: 13px; }
    li { color:#4A4A4A; padding-bottom: 21px; font-weight: 400; font-size: 16px; line-height: 18px; }
  }
  // .urgency:last-child { padding-bottom: 75px; }
  .urgency {
    & { margin: 0 auto; padding: 1rem 0; width: 95%; max-width: 777px; }
    h2 { font-family: $primaryfont; font-weight: 700; font-size: 18px; line-height: 25px; text-align: start; margin: 15px auto 15px; }
    .urgency-slider { 
      & { position: relative; background-color: rgba(115, 183, 111, 0.29); margin: 0 auto; padding: 115px 9% 80px; border-radius: 10px; }
      h3 { position: absolute; top: 0; color: #858585; font-weight: 400; font-size: 15px; left: 4%; }

      .slider-labels {
        & { width: 100%; font-weight: 400; font-size: 13px; margin: 0 auto; }
        .label {
          & { width: 20%; float: left; position: relative; }
          p { display: block; position: absolute; margin-left: -48%; margin-top:-5px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000;color:#858585;font-size:13px;font-weight:400 ; }
        }
      }
    
    }

    .slider-points {
      & { margin-top: -20px; width: 100%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
      .score { position: absolute; z-index: 9999; margin-left: -12px; margin-top: -9px; text-align: center; font-size: 18px; font-weight: 700; color: #fff; }
      .top-labels { position: absolute; width: 1px; height: 31px; top: -44px; margin-left: -2px; background-color: #73B76F; }
      .top-points { position: absolute; top: -70px; margin-left: -6px; font-weight: 700; font-size: 18px; color: #5E5E5E; }
      .point {
        & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -6px; background-color: #73B76F; position: absolute; z-index: 9; }
      }
    }

    .custom-slider.MuiSlider-root { & { z-index: 1000; color:rgba(115, 183, 111, 0.4223); width: 100%; margin-left: 0px; }
      span.MuiSlider-track { left: -8px !important; }
    }

    .custom-slider .MuiSlider-rail { opacity: 0; }
    .custom-slider .MuiSlider-track { border: 8px solid rgba(115, 183, 111, 0.4223); height: 2px; }

    .custom-slider .MuiSlider-thumb { background-color: #73B76F; }
    .custom-slider .MuiSlider-thumb:before { background-color: #73B76F; border: 4px solid #73B76F; width: 36px; height: 36px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
    .custom-slider.individual .MuiSlider-thumb:before { background-color: #73B76F; border: 4px solid #73B76F; width: 24px; height: 24px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
    .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
    .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
    
    .MuiLinearProgress-root.custom-bar { background-color: #D8D8D8; height: 2.5px; }
    .custom-bar .MuiLinearProgress-bar { background-color: #D8D8D8; }
  }
}


@media only screen and (max-width: 830px) {

  main .dashboard .align-details {
    .MuiPaper-root.MuiAccordion-root { box-shadow: none; outline: none; }
    .MuiPaper-root.MuiAccordion-root:before { background-color: transparent; }
    .MuiAccordion-root.Mui-expanded { margin: 0 auto; }
    .MuiAccordionSummary-root {
      & { min-height: 48px; border-top: 1px solid #343333; border-bottom: none; font-size: 14px; }
      .MuiAccordionSummary-expandIconWrapper { color: #0E435C; }
    }
    .MuiAccordionSummary-root.Mui-expanded { min-height: 48px; border-top: 1px solid #343333; }
    .MuiAccordionSummary-content {
      // & { margin: 0 auto; }
      .answer { text-transform: uppercase; }
      .true { color: #73B76F; }
      .false { color: #FF6645; }
      .neutral { color: #FDBF04; }
      .exception { color: #0E435C; }
    }
    .MuiAccordionSummary-content.Mui-expanded { & { margin: 0 auto; }}
    .MuiAccordionDetails-root { padding: 13px 16px 13px; border-top: 1px solid #343333; font-size: 15px; }
    // .MuiAccordionDetails-root:last-child { border-bottom: 1px solid crimson; }
  }
  .score-section-container .rowdata .colscore .bar { height: 10.88px; }
  main .results.individual .score-section-container .rowdata .colscore .bar { height: 10.88px; }
}
  
@media only screen and (max-width: 650px) {


  main  .dashboard {
    h1 { font-size: 30px; padding: 24px 16px 16px; }
    .progress-bar { width: 90%; position: relative; margin: 60px auto 5px; max-height: 120px; }
    .result-sections .score-section { font-weight: 400; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: -15px; }

  }
  main .dashboard { & {  }
    .pervalue { font-size: 14px; top: -18px; }
    .si_pro_header { bottom: 85px; }
    .pervalue-text { & { bottom: 70px; margin: 10px auto; position: relative; width: 100%; }
      p { display: inline-block; text-align: center; margin: 0 auto; font-size: 13px; color: #4A4A4A; }
    }
    .result-text { font-size: 18px; padding: 15px 18px; }
    .score-section-container {
      // & {grid-template-columns: repeat(1, 1fr);grid-template-rows: repeat(1, 1fr);flex-wrap: wrap; padding: 0 0 20px 0; max-width: 90%; margin-top: 0; }
      & { grid-template-columns: repeat(1, 1fr);grid-template-rows: repeat(1, 1fr); }
      .score-section { 
        & { margin: 15px auto; width: 100%; max-width: 260px; flex: 0 1 65% }
        .score-txt { font-size: 18px; font-weight: 700; margin: 15px auto; }
        .score-name { font-size: 18px; font-weight: 700; min-width: 12ch; margin: 0.25rem auto; }
        .score-slider { width: 90%; margin: 0 auto 15px; max-width: 250px; }
        
      }
    }
  }

  main .dashboard {
    .alignment { width: 100%; }
    .alignment .content .align-details .answered-detail .more-details .more-row { 
      & { justify-content: space-between; }
      .answer { font-size: 14px; }
    }
    //  .alignment .tab-buttons button:hover { background-color: #FF9045; }
    .alignment {
      .tabs-content {
        & { background-color: #EEEEEE; padding: 5px 7px; }
        .content .align-details { margin: 0 auto 10px auto; }
      }
      .tab-buttons { flex-wrap: wrap; padding: 10px 5px; box-sizing: border-box; }
      .tab-buttons button {
        & { background-color: #97979733; font-size: 15px; min-width: 47%; padding: 10px 5px; flex: 1 1 47%; border-radius: 5px; margin: 5px; }
        &, &:hover { border-radius: 5px; }
        &.active { border-radius: 5px; background-color: #0E435C; }
      }
    }
  }

  
}

@media only screen and (max-width: 550px) {
  
  main .MuiSwitch-thumb { background-color: #FC2B38 !important; position: relative; }
  main .MuiSwitch-thumb::after { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); content: ""; width: 3px; height: 3px; border: 3px solid #FFFFFF; border-radius: 50%; padding: 2px; background-color: transparent !important; }
  main .MuiSwitch-track { background-color: #E7E9EB !important; opacity: 1 !important; }

  main .dashboard {
    & { width: 95%; margin-top: 15px; }
    h1 { font-size: 21px; padding: 1.5rem 0px 1rem; width: auto; line-height: 30px; }
    .progress-bar {
      .pervalue { top: -64px; }
      .si_pro_header { bottom: 72px; }
      .si_ass_progress .score-arrow { bottom: 32px; }
      .si_ass_progress .msi_score { font-size: 16px; padding: 4px 2px; bottom: 35px; margin-left: -25px; }
    }
    .alignment {
      & { width: 100%; margin: 30px auto 15px; }
      .content { & {  }
        .align-details { font-size: 16px; }
        .align-details .answered-detail p { flex: 0 1 90%; }
        .align-details .answered-detail .more-toggle { flex: 0 1 8%; }
        .align-details .answered-detail { font-size: 16px; padding: 10px 12px; width: 100%; box-sizing: border-box; }
      }
    }
    .most-answered { margin: 10px auto; font-size: 16px; }
    .most-answered .most-answered-detail { font-size: 15px; padding: 10px 15px; width: 100%; box-sizing: border-box; }
  }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .avg-percentage-container .statistics .sec-container .tools-name { font-size: 11px; font-weight: 400; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .avg-percentage-container .statistics .percentages { font-size: 11px; font-weight: 400; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .avg-percentage-container .statistics .per-bar .percent-bar { height: 13px; }
  .dashboard .pervalue h4.nimp1:after { top: 45px; font-size: 8px; right: -13px; }
  .dashboard .pervalue h4.nimp:after { top: 45px; font-size: 8px; right: -13px; }
  .dashboard .pervalue h4.mexpe:after { top: 45px; font-size: 8px; right: -13px; }
  .dashboard .pervalue h4.eexpe:after { top: 45px; font-size: 8px; right: -13px; }
  .dashboard .pervalue h4.eexpe1:after { top: 45px; font-size: 8px; right: -13px; }
  .dashboard .pervalue h4.eexpe2:after { top: 45px; font-size: 8px; right: -13px; }

  .dashboard .si_pro_header h4.nimp:before { height: 18px; }
  .dashboard .si_pro_header h4.mexpe:before { height: 18px; }
  .dashboard .si_pro_header h4.eexpe:before { height: 18px; }
  .dashboard .si_pro_header h4.eexpe1:before { height: 18px; }

  main .dashboard .pervalue-text { bottom: 40px; }


  // main .individual-group { display: none; }
  main .individual-group { display: block; }
  // main .toggle-individual-group { display: block; margin: 15px auto; display: flex; align-items: center; justify-content: center; color: #fff; font-size: 21px; }
  // main .toggle-individual-group .left { flex: 0 1 25%; text-align: right; color: #ffffff; }
  // main .toggle-individual-group .right { flex: 0 1 35%; color: #ffffff; }
  // main .toggle-individual-group .active { font-weight: 700; color: #ED1B24; color: #ffffff; }
  
  main .dashboard .mobile_scores { display: block; }
  main .dashboard .result-sections { margin: 50px auto; padding-bottom: 0; }
  main .dashboard .result-sections .score-section { flex: 0 1 50%; }
  main .dashboard .result-sections .score-section .score-txt { margin: 10px auto; }
  main .dashboard .result-sections .score-section .score-name { font-weight: 400; margin: 10px auto 0; }
  main .dashboard .progress-bar { width: 90%; }
  main .dashboard .si_ass_progress .msi_score { font-size: 24px; }
  main .dashboard hr { display: none; }
  main .dashboard table { font-size: 13px; display: none; }
  main .dashboard table .footer .church-average { font-size: 13px; }
  main .dashboard table .header .center-cell { font-size: 0; }

  main .dashboard .hurdles-enablers ul { & { padding-left: 30px; } 
    li { font-size: 14px; } 
  }

  main .individual-group {
    & {  width: 80%; margin: 30px auto 45px; display: flex; height: 49px; border-radius: 25px; background-color: #ffffff; overflow: hidden; }
      button { cursor: pointer; flex: 0 1 50%; border: 0px; background: white; color: #1F2A44; font-family: $primaryfont; font-size: 18px; font-weight: 600; padding: 10px 0; text-align: center; }
      .left { border-radius: 25px  25px; font-weight: 400; transition: all ease-in-out 0.2s; }
      .right { border-radius:  25px 25px 0; font-weight: 400; transition: all ease-in-out 0.2s; background-color: #FFFFFF; }
      .left:hover, .right:hover { transition: ease-in-out 0.2s; opacity: 20px; }
      .active { background-color: #8CC63F; color: #ffffff; font-weight: 700; }
  }

  .dashboard {
    .score-section-container {
      & { gap: 10px }
      .rowdata { 
        .colnname {
          & { width: 40%; font-size: 9px; margin: 0; font-weight: 400; line-height: 10.8px;text-align: left; }
        }
        .colscore { & { height: 10.88px; } 
          h3 { font-size: 10px; }
        }
      }
    }
  }
  main .results.individual .score-section-container .rowdata .colnname { width: 40%; font-size: 9px; margin: 0; font-weight: 400; line-height: 10.8px; text-align: left; }
  main .results.individual .score-section-container .rowdata .colscore { height: 10.88px; }
  main .results.individual .score-section-container .rowdata .colscore h3 { font-size: 10px; }
  .score-section-container .rowdata .colscore div { border-radius: 0 5px 5px 0; }
  main .results.individual .score-section-container .rowdata .colscore div { border-radius: 0 5px 5px 0; }
  .dashboard-page .accordions-section-container .toolkit-container button img { height: 14px; width: 18px; }
  .dashboard .pre-score-header { font-family: $fontLato; font-size: 18px; font-weight: 400; line-height: 28px; text-align: center; color: #555555; }

  .dashboard-page {
    .accordions-section-container {
      .toolkit-container {
        .toolkit {  width: 100%;
          .toolkit-body {
            .content-details {
              .usage-stats {  width: 100%;
                .stat-row { display: flex; align-items: center; margin-bottom: 10px;
                  .tool-name { font-size: 12px; flex: 0 0 60px;  text-align: right; padding-right: 10px; }
                  .percentage-bar-container { flex-grow: 1; position: relative; height: 20px;
                    .percentage-bar { position: absolute; left: 0; top: 0; height: 100%; text-align: left;
                      .percentage { position: absolute; left: 5px; top: 50%; transform: translateY(-50%); font-size: 10px; color: white; }
                    }
                  }
                  .multiplier-container { display: flex; align-items: center; margin-left: 5px;
                    .multiplier { font-size: 12px; margin-right: 3px; }
                    .user-icon { width: 15px; height: 15px; }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  .dashboard-page .accordions-section-container .note-container { width: 95%; padding: 13px 8px; }

}


